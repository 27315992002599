import React, { createContext, useContext, useEffect, useState  } from "react";
import firebase from "firebase/app";
import "firebase/auth";

const defaultContext = { currentUser: null }
const AuthContext = createContext(defaultContext);
export const useAuth = () => useContext(AuthContext);
export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        setCurrentUser(user);
      }
    });
  }, []);


  return (
    <AuthContext.Provider value={{currentUser, setCurrentUser}}>
      {children}
    </AuthContext.Provider>
  );
};
