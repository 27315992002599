// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ados-js": () => import("./../../../src/pages/ados.js" /* webpackChunkName: "component---src-pages-ados-js" */),
  "component---src-pages-aines-js": () => import("./../../../src/pages/aines.js" /* webpackChunkName: "component---src-pages-aines-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-donation-js": () => import("./../../../src/pages/donation.js" /* webpackChunkName: "component---src-pages-donation-js" */),
  "component---src-pages-enfance-js": () => import("./../../../src/pages/enfance.js" /* webpackChunkName: "component---src-pages-enfance-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jeunesse-js": () => import("./../../../src/pages/jeunesse.js" /* webpackChunkName: "component---src-pages-jeunesse-js" */),
  "component---src-pages-nous-js": () => import("./../../../src/pages/nous.js" /* webpackChunkName: "component---src-pages-nous-js" */),
  "component---src-pages-osmose-js": () => import("./../../../src/pages/osmose.js" /* webpackChunkName: "component---src-pages-osmose-js" */),
  "component---src-pages-pasteurs-js": () => import("./../../../src/pages/pasteurs.js" /* webpackChunkName: "component---src-pages-pasteurs-js" */),
  "component---src-pages-predications-js": () => import("./../../../src/pages/predications.js" /* webpackChunkName: "component---src-pages-predications-js" */),
  "component---src-pages-relation-aide-js": () => import("./../../../src/pages/relation-aide.js" /* webpackChunkName: "component---src-pages-relation-aide-js" */),
  "component---src-pages-rendez-vous-js": () => import("./../../../src/pages/rendez-vous.js" /* webpackChunkName: "component---src-pages-rendez-vous-js" */),
  "component---src-pages-servir-js": () => import("./../../../src/pages/servir.js" /* webpackChunkName: "component---src-pages-servir-js" */),
  "component---src-pages-supervise-js": () => import("./../../../src/pages/supervise.js" /* webpackChunkName: "component---src-pages-supervise-js" */),
  "component---src-pages-temoignages-js": () => import("./../../../src/pages/temoignages.js" /* webpackChunkName: "component---src-pages-temoignages-js" */)
}

