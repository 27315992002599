import "./src/styles/global.css";
import firebase from "firebase/app";
import "firebase/remote-config";
import "firebase/firestore";
import "firebase/analytics"
import React from "react"
import { AuthProvider } from "./src/services/AuthContext";

var firebaseConfig = {
  apiKey: "AIzaSyA5Ue5e_oiIAjIq_0OATLtYKiesn_3jh5c",
  authDomain: "pdv-orleans-dev.firebaseapp.com",
  databaseURL: "https://pdv-orleans-dev.firebaseio.com",
  projectId: "pdv-orleans-dev",
  storageBucket: "pdv-orleans-dev.appspot.com",
  messagingSenderId: "888297678864",
  appId: "1:888297678864:web:6f0b929293657fc64c4295",
};

firebase.initializeApp(firebaseConfig);

const remoteConfig = firebase.remoteConfig();
remoteConfig.settings.minimumFetchIntervalMillis = 3600000;

export const wrapRootElement = ({ element }) => {
  return (
    <AuthProvider>
      {element}
    </AuthProvider>
  )
}
